<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/tracings`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-card>
      <h4>
          {{$t("Labels.TracingInformation")}}
      </h4>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="groupValidation">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Labels.Name')" label-for="name" >
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-input id="name" v-model="tracing.name" :state="errors.length > 0 ? false:null" name="name" :placeholder="$t('Labels.Name')" maxlength="254"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Labels.AdditionalInformation')" label-for="description">
            <validation-provider #default="{ errors }" name="description" rules="required">
              <b-form-textarea id="description" v-model="tracing.description" :state="errors.length > 0 ? false:null" name="description" :placeholder="$t('Labels.Description')" no-resize rows="3" maxlength="200"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('Labels.DistanceConvoy')+' (mt)'"
            label-for="distance"
          >
            <validation-provider
              #default="{ errors }"
              name="distance"
              rules="required"
            >
              <b-form-spinbutton
                id="distance"
                 v-model="tracing.distance"
                :state="errors.length > 0 ? false:null"
                name="distance"
                min="1"
                max="10000"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            :label="$tc('Trackers',0)"
            label-for="device"
          >
            <validation-provider
              #default="{ errors }"
              name="device"
              rules="required"
            >
              <b-form-select
                name="device"
                :state="errors.length > 0 ? false:null"
                :options="trackers"
                v-model="tracing.tracker_uuid"
                :reduce="v => v.uuid"
                value-field="uuid"
                text-field="name"
                :clearable="false"
                multiple
                :select-size="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      </validation-observer>
      <b-row>
        <b-col cols="12" md="6" offset-md="6">
          <b-button
            type="submit"
            variant="primary"
            block
            @click="validationForm"
            class="mt-3"
          >
            {{$t("Labels.Save")}}
          </b-button>
        </b-col>
      </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormSpinbutton,
  BFormSelect
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'

const DATA = {
  code: 200,
  data: {},
  msg: 'Ok'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSpinbutton,
    BFormSelect
  },
  data () {
    return {
      tracing: {
        tracker_uuid: []
      },
      members: [],
      required,
      DATA,
      uuid: this.$route.params.uuid,
      trackers: []
    }
  },
  methods: {
    async add () {
      const params = {
        url: 'tracing',
        method: 'POST',
        params: {
          name: this.tracing.name,
          description: this.tracing.description,
          distance: this.tracing.distance,
          tracker_uuid: this.tracing.tracker_uuid.join(',')
        }
      }
      await request(params).then(response => {
        this.$router.push('/tracings')
      })
    },
    validationForm () {
      this.$refs.groupValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    },
    async getTrackers () {
      const params = {
        url: 'tracker/list?unassigned=1',
        method: 'GET'
      }
      await request(params).then(response => {
        this.trackers = response.data.data
      }).catch(() => {
        this.trackers = []
      })
    }
  },
  created () {
    this.getTrackers()
  }
}
</script>
