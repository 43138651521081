var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-1",attrs:{"type":"submit","variant":"primary","to":"/tracings","size":"md"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Labels.Back')))])],1),_c('b-card',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("Labels.TracingInformation"))+" ")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"groupValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.Name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":_vm.$t('Labels.Name'),"maxlength":"254"},model:{value:(_vm.tracing.name),callback:function ($$v) {_vm.$set(_vm.tracing, "name", $$v)},expression:"tracing.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.AdditionalInformation'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"name":"description","placeholder":_vm.$t('Labels.Description'),"no-resize":"","rows":"3","maxlength":"200"},model:{value:(_vm.tracing.description),callback:function ($$v) {_vm.$set(_vm.tracing, "description", $$v)},expression:"tracing.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Labels.DistanceConvoy')+' (mt)',"label-for":"distance"}},[_c('validation-provider',{attrs:{"name":"distance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-spinbutton',{attrs:{"id":"distance","state":errors.length > 0 ? false:null,"name":"distance","min":"1","max":"10000"},model:{value:(_vm.tracing.distance),callback:function ($$v) {_vm.$set(_vm.tracing, "distance", $$v)},expression:"tracing.distance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$tc('Trackers',0),"label-for":"device"}},[_c('validation-provider',{attrs:{"name":"device","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"name":"device","state":errors.length > 0 ? false:null,"options":_vm.trackers,"reduce":function (v) { return v.uuid; },"value-field":"uuid","text-field":"name","clearable":false,"multiple":"","select-size":5},model:{value:(_vm.tracing.tracker_uuid),callback:function ($$v) {_vm.$set(_vm.tracing, "tracker_uuid", $$v)},expression:"tracing.tracker_uuid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('b-button',{staticClass:"mt-3",attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" "+_vm._s(_vm.$t("Labels.Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }